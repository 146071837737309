import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { Router } from '@angular/router'
import { Subject, map, switchMap, take, takeUntil, tap } from 'rxjs'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { StoreService, Organization, UserService, OrganizationsService } from 'shared-lib'

const ORG_TYPES = {
  1: _('employer'),
  2: _('gigger')
}

const ANIMATION_TIMINGS = '225ms cubic-bezier(0.4, 0.0, 0.2, 1)'

@Component({
  selector: 'app-org-switcher',
  templateUrl: './org-switcher.component.html',
  styleUrls: ['./org-switcher.component.scss'],
  animations: [
    trigger('slideContent', [
      state('void', style({ transform: 'translate3d(0, 25%, 0)', opacity: 0 })),
      state('enter', style({ transform: 'none', opacity: 1 })),
      state('leave', style({ transform: 'translate3d(0, 25%, 0)', opacity: 0 })),
      transition('* => *', animate(ANIMATION_TIMINGS))
    ]),
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', [
        animate('0.2s')
      ])
    ])
  ]
})
export class OrgSwitcherComponent implements OnInit, OnChanges, OnDestroy {
  @Input() sidebarState: 'opened' | 'collapsed' | 'closed'

  public isOpen = false
  public emptyAvatarUrl = 'assets/user/anonymous.png'
  public isLoading = false
  public animationState: 'void' | 'enter' | 'leave' = 'enter'
  public organization: Organization
  private destroy$ = new Subject<void>()

  constructor (
    private userService: UserService,
    private snackBar: MatSnackBar,
    private router: Router,
    private organizationsService: OrganizationsService,
    private storeService: StoreService
  ) { }

  ngOnChanges (changes: SimpleChanges): void {
    this.isOpen = false
  }

  private organizations: Organization[]
  public organizationsForUser: Organization[]

  ngOnInit (): void {
    console.log('init org-switcher')
    this.initializeData()
  }

  private initializeData () {
    this.isLoading = true

    this.storeService.organization$
      .pipe(
        take(1),
        switchMap(currentOrg => {
          this.organization = currentOrg
          return this.organizationsService.fetchOrganizationsMemberIn()
        }),
        map(orgs => orgs.filter(org => org.type !== undefined && org.type !== null)),
        map(orgs => orgs.map(org => {
          // Assuming ORG_TYPES is a mapping to transform numerical type codes to string labels
          if (typeof org.type === 'number') org.type = ORG_TYPES[org.type]
          return org
        })),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: orgs => {
          this.organizations = orgs
          this.getOrganizationsForUser(this.organization.id)
          this.isLoading = false
        },
        error: err => {
          console.error('An error occurred:', err)
          this.isLoading = false
        }
      })
  }

  ngOnDestroy (): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private getOrganizationsForUser (current_org_id) {
    this.organizationsForUser = this.organizations.filter(org => org.id !== current_org_id)
  }

  public switchOrg (org_id) {
    this.isLoading = true
    this.toggleOpen()

    return this.userService.changeCurrentOrgFlag(org_id)
      .pipe(
        take(1),
        tap(() => this.getOrganizationsForUser(org_id)),
        switchMap(() => this.storeService.organization$.pipe(take(1)))
      )
      .subscribe({
        next: (currentOrg: Organization) => {
          this.organization = currentOrg
          this.isLoading = false
          this.onOrgSwitched()
        },
        error: () => {
          this.snackBar.open(_('Error: User doesn\'t belong to the Organization'), null, { panelClass: ['shoutly-snack-bar', 'error'] })
        }
      })
  }

  private onOrgSwitched () {
    this.snackBar.open(_('Organization switched'), 'ok', { panelClass: ['shoutly-snack-bar', 'success'] })
    return this.router.navigate(['.'])
  }

  public toggleOpen () {
    this.isOpen = !this.isOpen
  }
}
