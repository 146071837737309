<div class="org-switcher-wrapper" [ngClass]="sidebarState" *ngIf="isLoading">
    <div class="button-content switcher-button" @fadeInOut>
        <div class="button-content">
            <div class="button-content">
                <div class="item skeleton circle"></div>
                <div class="content-description" *ngIf="sidebarState === 'opened'">
                    <div class="item skeleton line"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="org-switcher-wrapper" [ngClass]="sidebarState" *ngIf="!isLoading && organization && organizationsForUser">
    <!-- This button triggers the overlay and is it's origin -->
    <div class="switcher-button" (click)="toggleOpen()" aria-label="organization switcher" cdkOverlayOrigin
        #trigger="cdkOverlayOrigin" [class.disabled]="!organizationsForUser">
        <div class="button-content" @fadeInOut>
            <ng-container *ngIf="isLoading">
                <mat-spinner [color]="'warn'" [diameter]="25"></mat-spinner>
            </ng-container>
            <ng-container *ngIf="!isLoading">
                <img height="25" width="25" class="avatar" [src]="organization.avatar">
            </ng-container>
            <div class="content-description" *ngIf="sidebarState === 'opened'">
                <span class="name" aria-label="organization name">{{ organization.name }}</span><br>
                <span class="type"
                [attr.data-testid]="'current-org-type'"
                [attr.data-testvalue]="organization?.type"
                [ngClass]="organization?.type"
                aria-label="organization type">{{ organization?.type | translate | titlecase }}</span>
            </div>
            <div>
                <shared-expansion-arrow-rotate *ngIf="organizationsForUser?.length > 0"
                    [open]="isOpen"></shared-expansion-arrow-rotate>
            </div>
        </div>
    </div>

    <!-- This template displays the overlay content and is connected to the button -->
    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen"
        [cdkConnectedOverlayWidth]="trigger.elementRef.nativeElement.offsetWidth">
        <div class="org-switcher-expanded overlay-wrapper mat-body" [@slideContent]="animationState"
            aria-label="organization switcher opened">
            <div class="orgs-wrapper">
                <div class="overlay-content" *ngFor="let orgItem of organizationsForUser"
                    (click)="organization.id != orgItem.id ? switchOrg(orgItem.id) : null">
                    <img height="25" width="25" class="avatar" [src]="orgItem.avatar ? orgItem.avatar : emptyAvatarUrl">
                    <div class="content-description" *ngIf="sidebarState === 'opened'">
                        <span class="name" aria-label="organization name opened">{{ orgItem.name }}</span><br>
                        <span class="type"
                        [attr.data-testid]="'opened-type'"
                        [attr.data-testvalue]="orgItem?.type"
                        [ngClass]="orgItem?.type"
                        aria-label="organization type opened">{{ orgItem?.type | translate | titlecase }}</span>
                    </div>
                </div>
            </div>
        </div>

    </ng-template>
</div>