<button 
    mat-button
    [class.active]="isActive"
    [matMenuTriggerFor]="menu"
    #menuBtn="matMenuTrigger">
    <div class="user-profile-nav" 
    [class.mobile]="isHandset$ | async">
    <ng-container *ngIf="user">
        <div class="column user-avatar">
            <img [src]="user.avatar">
        </div>
        <div class="column user-info">
            <div class="user-name">{{ user.first_name | titlecase }}</div>
            <div class="user-position">{{ user.position || 'position' | titlecase }}</div>
        </div>
        <div class="column user-role">
            <div class="role">
                <span class="strong">{{ user?.role?.name }}</span>
            </div>
        </div>
    </ng-container>
    <div class="column arrow">
        <shared-expansion-arrow-rotate [open]="menuBtn.menuOpen"></shared-expansion-arrow-rotate>
    </div>
</div>
</button>

<mat-menu #menu="matMenu" xPosition="before">
    <button mat-menu-item [routerLink]="['/notifications']" [attr.data-testid]="'user-cabinet-menu-notif-button'">
        <mat-icon fontSet="material-icons-outlined" matListItemIcon>notifications</mat-icon>
        <div matListItemTitle>{{ 'notifications' | translate | titlecase }}</div>
    </button>

    <button mat-menu-item [routerLink]="['/settings']">
        <mat-icon fontSet="material-icons-outlined" matListItemIcon>settings</mat-icon>
        <div matListItemTitle>{{ 'settings' | translate | titlecase }}</div>
    </button>

    <button mat-menu-item (click)="goToHelpCenter($event)" [attr.data-testid]="'user-cabinet-menu-item-qa'">
        <mat-icon fontSet="material-icons-outlined" matListItemIcon>help_center</mat-icon>
        <div matListItemTitle>{{ 'help center' | translate | titlecase }}</div>
    </button>

    <mat-divider></mat-divider>

    <button mat-menu-item [routerLink]="['/auth/logout']">
        <mat-icon fontSet="material-icons-outlined" matListItemIcon>logout</mat-icon>
        <div matListItemTitle>{{ 'logout' | translate | titlecase }}</div>
    </button>
</mat-menu>